import React from 'react';
import './style.css';
import bt from './img/bt.png'


function Buy() {

  const bbs = () => {
    return (
      <svg fill='#fff' version="1.1" viewBox="0 0 1346 202">
        <path class="cls-1" d="M115,89c12.5,8.4,16.6,20.1,15.9,34-.7,13.6-10,22.6-23.6,22.6-23.5,0-47,0-70.5,0-7.6,0-11.6-3.5-11.6-11.2-.2-27.7-.2-55.3,0-83,0-8.2,3.7-11.4,11.8-11.4,20.3,0,40.7,0,61,0,15.1,0,24.1,9.3,24.3,24.4.1,8.8-2.2,16.7-7.3,24.5ZM44.1,127.3c1.8.2,3.1.4,4.4.4,18.8,0,37.6,0,56.4,0,3.1,0,7,.3,7.6-3.8,1.5-9.8-2.1-21.8-16-21.8-10.2,0-20.3,0-30.5,0-13.7,0-13.6,0-13.4-13.7,0-3.9,1.5-4.8,5-4.7,9.3.2,18.6,0,28,0,12.5,0,18.3-6,18.3-18.4,0-6.3-.7-7-6.8-7-16,0-32,0-47.9,0-1.6,0-3.2.2-5,.3v68.7Z"/>
        <path class="cls-1" d="M436.6,89c11.2,7.5,15.4,18,16,30.5.7,15.4-8.9,26-24.2,26-23.2,0-46.3,0-69.5,0-8.8,0-12-3.1-12-12.1,0-27.2,0-54.3,0-81.5,0-8.9,3.2-12,12.1-12,20,0,40,0,60,0,15.7,0,24.8,9.2,24.9,24.8,0,8.6-2.4,16.4-7.3,24.1ZM374.3,83.7c10.8,0,21.1,0,31.4,0,14.7,0,20-5.7,19.5-20.5-.1-3.5-1.3-5.2-5-5.1-17,0-34,0-51,0-2.9,0-4.2.7-4.2,3.9.1,20.5.1,41,0,61.4,0,3.3,1.3,4.5,4.5,4.3.3,0,.7,0,1,0,18.5,0,37,.1,55.5-.1,2.6,0,6.8-1.2,7.6-3,4.6-10.3-3.6-22.4-15.2-22.5-12.8-.2-25.6,0-38.5,0-1.8,0-3.6-.2-5.6-.2v-18Z"/>
        <path class="cls-1" d="M966.2,40.3c0,5.5,0,10.4,0,15.4,0,3.5-2.6,2.8-4.7,2.8-15.8,0-31.6,0-47.5,0-4.7,0-9.3,0-14,0-7.2.2-11.1,4.8-11.1,12.5,0,7.7,4,12.2,11.3,12.3,14.8.1,29.7-.2,44.5.1,11.9.3,21.8,5.2,27.5,16.1,5.8,11.2,5.3,22.6-1.7,33.2-5.4,8.2-13.8,12.5-23.3,12.6-24.6.5-49.3.1-73.9.1-.5,0-.9-.2-1.8-.5v-17.3c1.8-.1,3.7-.3,5.6-.3,21.8,0,43.6,0,65.5,0,9.6,0,14.1-3.6,14.9-11.2.8-8.4-3.9-13.9-12.3-14-14-.1-28,0-42,0-15.8-.2-27-7.7-31.1-20.4-7-21.7,7.2-41.5,29.9-41.7,19.7-.1,39.3,0,59,0,1.5,0,3,.1,5.3.2Z"/>
        <path class="cls-1" d="M213.2,180v-18c2,0,3.8,0,5.5,0,9.3,0,18.7.1,28,0,6.1-.1,8.7-2.5,8.7-7.9.1-20.5.1-41,0-61.4,0-5.4-2.6-7.9-8.1-8-11.5-.1-23-.1-34.5,0-5.6,0-8.4,2.6-8.6,8.2-.2,8.8-.2,17.7,0,26.5.2,5.7,2.9,7.9,9.8,8,9,.2,18,0,27,0,1.8,0,3.6,0,5.6,0v18.2c-8.3,0-16.4.1-24.5,0-5.5-.1-11.1,0-16.4-1.1-11.8-2.4-19.6-12.3-19.8-24.3-.1-9.5-.2-19,0-28.5.3-14.1,10.5-24.8,24.6-25.2,12.8-.4,25.7-.4,38.5,0,14.6.5,24.8,11.5,24.9,26,.1,20.2,0,40.3,0,60.5,0,14.9-8.1,25.3-22.8,26.8-12.3,1.3-24.9.3-37.9.3Z"/>
        <path class="cls-1" d="M1045.3,100.9c-4.6,5-9.1,10-13.6,15-7.5,8.3-14.9,16.6-22.3,24.9-3,3.4-6.4,6-11.3,4-4.7-1.9-6.1-5.8-6.1-10.5,0-21.2,0-42.3,0-63.5,0-3.7,1.1-5,4.9-4.8,4.3.3,8.6,0,13.5,0v44.2c.4.2.8.4,1.2.6,1.8-1.8,3.6-3.5,5.3-5.3,10.5-11.6,20.9-23.3,31.3-35,3.2-3.6,6.7-5.7,11.4-3.8,4.6,1.9,6,5.7,5.9,10.5-.1,11.5,0,23,0,34.5,0,2.6.4,5.3,1.8,8.7,3.1-3.5,6.8-6.7,9.2-10.6,8.2-13.1,16.2-26.4,23.9-39.7,1.7-3,3.4-4.4,6.9-4.2,4.9.3,9.9,0,15.2,0-.3,1.4-.4,2.2-.7,2.9-10.4,22.1-22.2,43.3-39.5,60.9-4.6,4.7-10.2,8.5-15.5,12.4-2.1,1.5-4.8,2.3-7.4,2.9-7,1.5-12.3-2.6-12.4-9.8-.2-9.5,0-19,0-28.5,0-1.8,0-3.6,0-5.4l-1.5-.7Z"/>
        <path class="cls-1" d="M1256.7,127.2c10.9,0,20.8,0,30.8,0,10.1,0,11.6-1.5,11.6-11.6,0-7.5,0-15,0-22.5,0-6.1-2.3-8.4-8.5-8.5-11-.1-22,0-33,0-7.2,0-9.5,2.4-9.6,9.7,0,22.7,0,45.3,0,68,0,2.5,0,5,0,8.3-5.6,0-10.8.2-16.1-.2-.8,0-2.1-2.6-2.2-4.1-.2-8.7-.2-17.3-.2-26,0-16.8-.4-33.5.3-50.3.5-13,10.5-22.8,23.6-23.4,13.6-.6,27.3-.6,40.9,0,11.6.5,21.5,9.6,22.6,21.7,1,11.5,1.2,23.3.1,34.9-1.2,13.3-11.7,22.1-25.1,22.5-10.3.3-20.7,0-31,.1-3.3,0-4.5-1.1-4.3-4.3.2-4.5,0-8.9,0-14.1Z"/>
        <path class="cls-1" d="M1143,83.6c0-5.2-.2-9.7.1-14.1,0-1.2,2-3.1,3.1-3.1,13.8-.1,27.7-.5,41.5.2,13.7.7,23.2,11.7,23.3,25.3,0,9,0,18,0,27,0,15.8-11,26.7-26.7,26.7-11.7,0-23.3.2-35,0-12.3-.2-23.1-9.2-25.1-20.5-2.4-13.1,4.1-25.4,16-30.2,1.7-.7,3.5-1.1,5.3-1.4,2-.3,4-.3,6-.3,10.6,0,21.3,0,32.4,0v17.7c-2.5,0-4.9,0-7.4,0-9,0-18-.1-27,.1-5,.1-8,3.4-8.1,8.4-.1,4.7,2.5,8.3,7.4,8.4,12.5.3,25,.3,37.5,0,4.5,0,6.8-3.2,6.9-7.7,0-9.5.1-19,0-28.5,0-5.3-3.1-7.9-9.4-7.9-11.5-.1-23,0-34.5,0-1.8,0-3.6,0-6.3,0Z"/>
        <path class="cls-1" d="M533.2,83.6v-16.6c1.5-.2,3.2-.5,4.9-.5,12.2,0,24.3,0,36.5,0,14.7,0,25.6,10.3,26.2,25.1.4,9.5.3,19,0,28.5-.5,15-11.5,25.5-26.5,25.6-11.7,0-23.3.2-35,0-13.4-.2-24-9.7-25.4-22.3-1.6-14,6.3-26,19.3-29.3,1.1-.3,2.3-.5,3.4-.5,11-.1,22,0,33-.3,3.6,0,4.5,1.2,4.6,4.6.2,13.1.3,13.1-12.6,13.1-7,0-14-.2-21-.2-5.8,0-9.3,3.2-9.5,8.6-.1,5.2,3,8.6,8.7,8.7,11.5.2,23,.2,34.5,0,6.1,0,8.6-2.9,8.6-9.2,0-8.5,0-17,0-25.5,0-6.9-2.7-9.6-9.7-9.7-11.5,0-23,0-34.5,0-1.8,0-3.6,0-5.7,0Z"/>
        <path class="cls-1" d="M696,127.4v17.9c-1.9,0-3.6.2-5.2.3-15,0-30,0-45,0-17,0-27.8-10.9-27.9-27.7,0-8.2,0-16.3,0-24.5,0-16.3,10.7-27,26.9-27,15.2,0,30.3,0,45.5,0h5.7v17.9c-2,0-3.8.2-5.6.2-14.8,0-29.7,0-44.5,0-7.3,0-9.6,2.2-9.7,9.5,0,7.7,0,15.3,0,23,0,7.9,2.2,10,10.2,10.1,14.5.1,29,.2,43.4.2,1.8,0,3.6,0,6.1,0Z"/>
        <path class="cls-1" d="M503.7,127.5v17.2c-16.5,4.9-34.1-6.6-34.4-23.4-.5-28.9-.1-57.9,0-86.8,0-.5.2-.9.4-1.7h17.3c.2,2.1.4,4.2.4,6.3,0,24.8,0,49.6,0,74.4,0,12.1,1.4,13.5,13.3,13.8.8,0,1.6.1,3.1.3Z"/>
        <path class="cls-1" d="M805.8,67.1c-14.3,11.6-28.6,23.1-43.4,35.1,15,14.5,29.5,28.5,44.8,43.3-9.1,0-17.3.1-25.4-.1-1.2,0-2.5-1.6-3.5-2.6-11.7-11.2-23.3-22.3-35-33.6-5.8-5.6-5.5-10.7.9-15.9,10.4-8.4,20.7-16.8,31.1-25.1,1.2-1,2.8-2.1,4.3-2.1,8.6-.2,17.2,0,25.8,0,0,.3.2.7.3,1Z"/>
        <path class="cls-1" d="M715.3,32.4c5.3,0,10.5-.2,15.6.2,1,0,2.5,2.7,2.5,4.2.2,18.6.2,37.3.2,55.9,0,15.5,0,30.9,0,46.4q0,6.4-6.6,6.4c-3.8,0-7.6,0-11.6,0V32.4Z"/>
        <path class="cls-1" d="M148.4,66.7h18.2v78.4h-18.2v-78.4Z"/>
        <path class="cls-1" d="M148.4,32.8h18.2v15.5h-18.2v-15.5Z"/>
      </svg>
    )
  }
  const cs = () => {
    return (
      <svg version="1.1" viewBox="0 0 462.1 144.8">
        <path class="cls-2" d="M25.6,57.9c-5-4.5-7.5-10.9-7.5-19.2s2.5-15,7.5-19.8c5-4.9,12.8-7.3,23.3-7.3s24.6,5.1,29.6,15.2c-4.2,1.4-9.1,3.6-14.8,6.5-1.4-2.6-3.5-4.6-6.4-5.8-2.9-1.3-5.9-1.9-8.9-1.9-4.5,0-7.8,1.2-10,3.5-2.2,2.4-3.3,5.3-3.3,8.7s1.2,6.8,3.5,9.1c2.4,2.4,6,3.5,10.8,3.5s7-.6,9.2-1.9c2.3-1.3,4-3.3,5-6.1,3.8,2.1,8.7,4.4,14.7,6.9-2.3,5.7-6.2,9.6-11.7,11.8s-11.4,3.4-17.9,3.4c-10.6,0-18.3-2.2-23.3-6.7Z"/>
        <path class="cls-2" d="M142.9,13c-.3,7.9-.5,16.4-.5,25.4s.2,17.5.5,25.4h-16.5v-19.1h-25.8v19.1h-16.5c.4-9.1.6-17.5.6-25.4s-.2-16.4-.6-25.4h16.5v19.1h25.8V13h16.5Z"/>
        <path class="cls-2" d="M189.1,27c-5.6-.3-9.7-.4-12.3-.5v24c2.7,0,6.8-.2,12.3-.5v13.9h-40.7v-13.9c5.4.3,9.5.4,12.3.5v-24c-2.8,0-6.9.2-12.3.5v-13.9h40.7v13.9Z"/>
        <path class="cls-2" d="M202.2,57.9c-5-4.5-7.5-10.9-7.5-19.2s2.5-15,7.5-19.8c5-4.9,12.8-7.3,23.3-7.3s24.6,5.1,29.6,15.2c-4.2,1.4-9.1,3.6-14.8,6.5-1.4-2.6-3.5-4.6-6.4-5.8-2.9-1.3-5.9-1.9-8.9-1.9-4.5,0-7.8,1.2-10,3.5-2.2,2.4-3.3,5.3-3.3,8.7s1.2,6.8,3.5,9.1c2.4,2.4,6,3.5,10.8,3.5s7-.6,9.2-1.9c2.3-1.3,4-3.3,5-6.1,3.8,2.1,8.7,4.4,14.7,6.9-2.3,5.7-6.2,9.6-11.7,11.8-5.5,2.2-11.4,3.4-17.9,3.4-10.6,0-18.3-2.2-23.3-6.7Z"/>
        <path class="cls-2" d="M297.4,63.9c-2.2-4-5.8-10-10.9-18.2l-8.2,8.1v10.1h-17.4c.4-9.1.6-17.5.6-25.4s-.2-16.4-.6-25.4h17.4v20.5c8.6-8.5,14.7-15.3,18.4-20.5h22.7l-22.1,21.9c3.3,4.6,6.9,9.5,11,14.9,4.1,5.4,7.8,10.1,11.1,14.1h-22Z"/>
        <path class="cls-2" d="M341.5,51.1c17.7,0,30.6-.4,38.6-.8-.3,1.9-.5,3.9-.6,5.9s-.1,4.6-.1,7.7h-54.4c.4-9.1.6-17.5.6-25.4s-.2-16.4-.6-25.4h54.4v12.7h-37.9v6.7c2.2,0,5.6,0,9.9,0,7.4,0,15.1-.1,23-.4v12c-7.9-.3-15.6-.4-23-.4s-7.7,0-9.9,0v7.3Z"/>
        <path class="cls-2" d="M444.4,13.1c-.3,7.9-.5,16.3-.5,25.4s.2,17.5.5,25.4h-25.1l-16.9-38.2,1.3,38.2h-18c.4-9.1.6-17.5.6-25.4s-.2-16.3-.6-25.4h25.8l17,39.3-1.2-39.3h17.2Z"/>
        <path class="cls-1" fill='#f19d12' d="M107.3,119.2c1.1-2.1,2.1-4.4,3-7.1,3.3,1.9,7.2,3.4,11.6,4.5,4.4,1.1,8.5,1.6,12.3,1.6s6.6-.3,8.2-1c1.6-.7,2.4-1.4,2.4-2.2,0-2.8-4.3-4.3-13-4.3s-16.4-1-20.7-3c-4.3-2-6.4-5.2-6.4-9.6,0-6.3,2.6-10.9,7.8-13.9,5.2-3,12-4.5,20.4-4.5s9.8.6,15,1.9c5.1,1.2,9.7,2.9,13.8,5.1-1.4,3.1-2.8,6.8-4.3,11.3-.3.9-.5,1.6-.7,2.1-2.4-1.9-6-3.5-10.8-4.8-4.8-1.3-9.1-1.9-12.7-1.9s-7.4.3-9,.9-2.3,1.4-2.3,2.6,1.1,2,3.2,2.7c2.1.7,5.2,1,9.2,1,9.9,0,17.1,1.1,21.6,3.4,4.5,2.3,6.7,5.7,6.7,10.4s-2.4,11.2-7.1,14.1-12,4.3-21.6,4.3-10.5-.6-15.8-1.7c-5.4-1.1-10.2-2.8-14.5-5,1.6-2.4,2.9-4.6,4-6.7Z"/>
        <path class="cls-1" fill='#f19d12' d="M215.7,112.8l3.6-31.7h17.1l-9.4,50.8h-21.5l-4.8-28.4-5.5,28.4h-20.6l-9.4-50.8h17.1l3.6,31.3,5.6-31.3h18.7l5.5,31.7Z"/>
        <path class="cls-1" fill='#f19d12' d="M280.7,131.9c-.9-2.7-2.1-6-3.6-10h-22.3l-3.5,10h-17.6l19.6-50.8h25.8l19.8,50.8h-18.1ZM273.1,110.9c-1.8-4.9-3.6-9.8-5.5-14.7l-1.7-4.6c-1.6,4.2-4,10.7-7.2,19.3h14.4Z"/>
        <path class="cls-1" fill='#f19d12' d="M334.3,81.1c9.3,0,15.8,1.6,19.4,4.9,3.6,3.3,5.5,7.9,5.5,13.9s-.7,7.6-2.2,10.5-4,5.1-7.6,6.7c-3.6,1.6-8.7,2.4-15.1,2.4h-14.6v12.3h-17.2c.4-9.1.6-17.5.6-25.4s-.2-16.4-.6-25.4h17.2c0,0,14.6,0,14.6,0ZM329.4,106c3.9,0,6.8-.2,8.7-.5,1.9-.3,3.3-.9,4.1-1.6.8-.8,1.2-2,1.2-3.5s-.4-2.7-1.1-3.5c-.7-.8-2.1-1.3-4-1.6-1.9-.3-4.9-.4-8.9-.4h-9.7v11.1h9.7Z"/>
      </svg>
    ) 
  }
  const sun = () => {
    return (
      <svg version="1.1" viewBox="0 0 449 124.1">
        <g>
          <circle cx="63.8" cy="62" r="57.6" fill="#fefefe" stroke-width="0"/>
          <path d="M118.8,67.7c-.3,2-1.6,2.2-3.3,2.1-3.9-.1-7.9,0-11.8,0-1,0-1.6,0-1.3-1.4.7-3.5.6-3.5,4.2-3.5,3.4,0,6.8,0,10.2,0,.7,0,1.4-.1,2,.4v2.5Z" fill="#f89f47" stroke-width="0"/>
          <path d="M24.8,59.5q.2,4.6-4.4,4.6c-3.8,0-7.7,0-11.5,0v-3.5c.2-.6.2-1.3,1.3-1.3,4.7,0,9.4,0,14.1,0,.2,0,.3,0,.5.2Z" fill="#f8a146" stroke-width="0"/>
          <path d="M118.8,62.4c-4.8,0-9.6-.1-14.3,0-1.6,0-1.6-.7-1.7-1.9,0-1.4.6-1.4,1.6-1.4,4.2,0,8.5,0,12.7,0,.6,0,1.2,0,1.7.4v2.8Z" fill="#f7a247" stroke-width="0"/>
          <path d="M68.8,14.9c0,2.5,0,5.1,0,7.6,0,.9-.2,1.4-1.2,1.1-1.2-.4-2.8.4-3.5-.5-.7-.9-.2-2.5-.2-3.8,0-4.1,0-8.1,0-12.2h.4c.9,1.3.4,2.8.5,4.3,0,.6,0,1.2,0,1.8,0,.9.3,1.3,1.2,1.2,1-.1,2,0,2.8.6Z" fill="#f5b346" stroke-width="0"/>
          <path d="M58.5,116.9c0-3,0-5.9,0-8.9.3-.3.7-.5,1.1-.6,3.2-1.3,3.7-.9,3.7,2.4,0,2.3.2,4.7-.2,7h-4.6Z" fill="#fb8c47" stroke-width="0"/>
          <path d="M63.1,116.9c0-2.6,0-5.2,0-7.7,0-2.1-.6-2.5-2.6-1.5-.7.3-1.3.4-2,.4,0-2.1,0-4.1,0-6.2,0-.3,0-.8,0-.8,1.4-.7,2.8.1,4.3,0,.8,0,.6.7.6,1.2,0,4.9,0,9.8,0,14.7h-.4Z" fill="#fa9048" stroke-width="0"/>
          <path d="M68.8,14.9c-1.3.3-2.5-.7-3.9.2-.6.4-.7-.5-.7-1,0-2.3,0-4.7,0-7h4.3c.8,2.6.3,5.2.3,7.8Z" fill="#f4b545" stroke-width="0"/>
          <path d="M66,116.9c0-2.8,0-5.7,0-8.5.4-.4.9-.4,1.4-.6.4,0,.8-.3.8-.8.1-1.6.2-3.2,0-4.9,0-.6-.7-.8-.9-1.3,1.3-.6,1.9-.3,1.8,1.3,0,4.3,0,8.7,0,13,0,.6,0,1.2-.3,1.7h-2.8Z" fill="#fb8e49" stroke-width="0"/>
          <path d="M60,23.4c-1.3.6-1.9.3-1.8-1.3,0-4.5,0-8.9,0-13.4,0-.6,0-1.2.3-1.7h2.8c0,1.1,0,2.1,0,3.2-2.3.4-2.4,2-2.3,3.9.1,2.1,0,4.2,0,6.3,0,1.1.2,2.1,1,2.9Z" fill="#f5b649" stroke-width="0"/>
          <path d="M24.8,59.5c-4.7,0-9.3,0-14,0-1,0-1.5.3-1.9,1.1v-1.4c4.7,0,9.4,0,14.1,0,.6,0,1.3-.3,1.8.3Z" fill="#f8ab59" stroke-width="0"/>
          <path d="M64.6,86.8c-.2,3.5-.5,7-.7,10.6,0,1.4-.6,1.5-1.7,1.4-12.8-.8-22.8-6.5-29.7-17.4-4.4-6.9-6.2-14.4-5.3-22.6.9.6.9,1.7,1.2,2.6,1.2,3.3,2.7,6.4,5.7,8.5,2.3,1.6,4.9,2.4,7.8,1.9,3.8-.6,7.5-1.4,11-3.1,2.5-1.2,4.8-2.8,6.5-5,.4-.5.7-1.2,1.5-1,1.5.2,3.6-.7,4.5.5.7,1,.2,2.9.1,4.4,0,1.2-.2,2.3-.3,3.5-.2,1.1-1.1.8-1.8.9-4.7.2-9.3.8-13.9,1.1-1.4,0-1.8.7-1.4,2.4,1.2,4.7,4.1,7.9,8.5,9.7,1.8.8,3.7,1.1,5.7,1.1.7,0,1.6-.2,2.2.5Z" fill="#f7b93a" stroke-width="0"/>
          <path d="M61,62.8c-5.1,7-12.7,9-20.7,9.7-3.6.3-6.5-1.6-8.8-4.4-2.2-2.7-3.5-5.9-4.2-9.2-.8-3,.2-5.6,1.6-8.2,2.8-3.3,6.8-4.3,10.7-5.3,7.4-1.9,15-2.6,22.5-3.5.5,0,.9,0,1.3.3,6.2-.5,12.4-1.2,18.7-1,3,0,6,.3,9.2.9-1.5,1.1-3.1,1.2-4.5,2.1.9.4,1.7.8,2.6,1.2-.2.3-.6.5-.9.4-2.6-.4-5.2-.2-7.8-.4-4.6-.3-9.2,0-13.7.7-3.2.6-4.1,2.4-2.9,5.5,1.8,4.8,4.9,8.1,9.8,9.7,5.4,1.7,10.9,2.1,16.4,1.4,2.1-.3,3.4-1.5,4.1-3.4.7-1.8,1.2-3.7,1.5-5.6,0-.6,0-1.3.6-1.8.5,1.1,1,2.3,1.5,3.6.6-1.5,1.1-2.8,1.7-4.3.8,4.2-2,11.5-5.2,13.9-.7.5-1.4,1-2.2,1.1-7.6,1.2-15.2,1.3-22.4-2-2.8-1.3-5.1-3.4-7-5.8-.7,1.6-1.4,3.1-2,4.6Z" fill="#01c0ad" stroke-width="0"/>
          <path d="M96.5,51.8c-.2,2.6-.7,5.1-1.6,7.6-.8,2.2-2.2,3.5-4.6,3.8-6.3.8-12.4.3-18.2-2.1-4.9-2-7.6-5.9-8.9-11-.5-1.8.2-3.1,1.9-3.9,1.8-.9,3.8-1.1,5.8-1.2,6.2-.3,12.4-.3,18.5.3,1.6.6,3.2,1.1,4.8,1.7.7.2.9.8,1.1,1.4.4,1.2.8,2.3,1.2,3.5Z" fill="#000" stroke-width="0"/>
          <path d="M63.5,42.1c-7.6,1.1-15.3,1.7-22.8,3.5-4.1,1-8.1,2.1-11.3,5-.1,0-.3,0-.5,0,5.2-14.2,15.3-22.6,30.3-24.9,1.6-.3,3.3-.3,4.9-.3,1,0,1.4.2,1.3,1.4-.7,5.1-1.2,10.2-1.9,15.3Z" fill="#f7b93a" stroke-width="0"/>
          <path d="M78,104.5c-.6,0-1.1,0-1.7,0-1.5.3-.7,1.6-1,2.4-1.7,1.3-2.1,1.3-3.6,0,0-1.9,0-3.9,0-5.8,0-.7.2-1.1.9-1.2,3.7-.8,7-2.3,10.4-3.9.8-.4,1.2-.2,1.8.3,3.2,3.2,6.4,6.4,9.6,9.6.8.8.7,1.1-.2,1.8-1.4,1.1-2.4,1.4-3.7-.2-1.6-2-3.6-3.6-5.3-5.4-.7-.7-1.2-1-2.1-.4-.9.5-.9,1.1-.6,1.9.2.6.3,1.2.5,1.9-1.7,0-3.4-.1-5-1Z" fill="#fa9147" stroke-width="0"/>
          <path d="M64.6,86.8c-4.3.2-8.3-.5-11.8-3.2-2.8-2.2-4.6-5-5.2-8.5-.4-2.1,0-2.3,2.2-2.5,5-.2,9.9-1,14.9-1.1.2,0,.4-.2.6-.3,3.8.2,7.6-.6,11.4-.7,2.1,0,2.5.3,2.2,2.4-.7,7.6-6.8,13.5-14.4,14Z" fill="#fd9d51" stroke-width="0"/>
          <path d="M71.6,7.8c.9.2,1.9.3,2.8.5.4,2.2.1,4.4.2,6.6,0,2.6,0,5.2,0,7.7,0,.5-.3,1.1.3,1.4,1.6.6,2.5-.1,3.1-1.6.9-1.9,2-3.7,3-5.5,2.3-.9,3.3-.3,3,1.9-.8,2.9-1.6,5.7-2.4,8.8-3.1-1.8-6.6-2.4-10-3.5-.4-3.3-.1-6.6-.2-10,0-2.1-.2-4.2.2-6.3Z" fill="#f5b145" stroke-width="0"/>
          <path d="M52.2,105.2c-1.7-.8-2.6-.2-3.4,1.5-.9,2-2,3.9-3,5.8-.3.6-.5,1.3-1.5.9-1.2-.4-.7-1.1-.5-1.8.7-2.4,1.4-4.7,2.1-7.1.2-.6.6-1.3.2-2-.3-.7.1-1.1.7-1,2.6.4,5.2-.7,7.7.1.5.2,1.1,0,1.3.7,0,4.2,0,8.5,0,12.7,0,.4,0,.8-.2,1.2-1.1.1-2.2,0-3.2-.6-.4-2.7,0-5.5-.2-8.2,0-.7.1-1.4-.1-2.1Z" fill="#fa9047" stroke-width="0"/>
          <path d="M25.4,86c.3-.8,1.9-1,1.1-2.4-.5-.9-1-1.3-2.1-.9-2.9,1-6,1.8-8.9,2.7-1,.3-1.3,0-1.7-.8-.5-1,.1-1.2.8-1.6,2.2-1.2,4.4-2.5,6.7-3.5,1.6-.7,2.6-1.5,1.8-3.4,1.4-.3,2.9-.2,4.3-.4.9,2.5,2.2,4.8,3.6,7.1,1.3,2.2,1.4,2.2-.7,4-1.7,0-3.4.5-4.8-.9Z" fill="#f99846" stroke-width="0"/>
          <path d="M104.4,78.3c3,1.6,6,3.2,9.1,4.7.9.5.7,1,.4,1.7-.3.9-.7,1-1.6.7-2.9-.9-5.7-1.8-8.6-2.6-.7-.2-1.4-.7-2,.3-.6.9-.7,1.6.1,2.3-.5,1.1-1.6.7-2.4.9-.7.2-1.4.4-2.2.5-1.6-1-1.9-2-.6-3.6,1.5-1.9,2.3-4.2,3.4-6.3,2.5-1.3,3.3-1,4.3,1.4Z" fill="#f99847" stroke-width="0"/>
          <path d="M55.7,102c-2.3.2-4.6-1-6.9.2-.4.2-1-.2-1.5-.4-.6-.2-1-.2-1.1.6-1.1-.9-2.2-.8-3.2.2-1-.1-2.1.1-3.1.1-.9,0-1.8,0-2.1-1.1,1.8-1.9,3.7-3.7,5.5-5.6.4-.4.7-.5,1.2-.2,3.5,2.1,7.3,3.3,11.2,4.4.3.6.3,1.2,0,1.7Z" fill="#fa9147" stroke-width="0"/>
          <path d="M102.9,50.3c-.2,2.3,0,2.6,2.3,2.6,3.8,0,7.7,0,11.5,0,2.2,0,1.5,1.7,1.7,2.7.3,1.5-1,1-1.7,1-4.3,0-8.6,0-12.9,0-1.1,0-1.5-.4-1.6-1.3-.2-1.2-.5-2.4-.7-3.6.4-.5.4-1.5,1.4-1.4Z" fill="#f7a548" stroke-width="0"/>
          <path d="M27.3,75.8c-1.4.9-2.8.9-4.3.4-3.5-.3-6.9,0-10.4-.1-1.7,0-2.9-1.6-2.6-3.2.1-.6.6-.5,1-.5,4.8,0,9.5,0,14.3,0,.6,0,1,.2,1.1.8.3.9.6,1.8.8,2.7Z" fill="#f89c48" stroke-width="0"/>
          <path d="M104.4,78.3c-.9-2.2-2.7-1.5-4.3-1.4,0-.2,0-.4,0-.5.7-1.3.5-3.3,1.7-3.9,1.2-.6,2.9-.2,4.3-.2,3.3,0,6.6,0,9.9,0,.5,0,1.8-.5,1.5.7-.2,1,.4,2.8-1.6,2.8-3.2,0-6.4,0-9.6,0-2,0-2.3.4-2,2.6Z" fill="#f89c47" stroke-width="0"/>
          <path d="M93.9,34.7c3.1-3.1,6.2-6.3,9.3-9.4.1-.1.3-.3.4-.3,1.6.1,1.9,1.7,2.9,2.5.6.5.7,1,0,1.6-2.3,2.3-4.6,4.7-7.1,7.1-.5-1.2-.9-2.2-1.4-3.4-.7.7-.7,1.5-1,2.3-.7.5-1.5.5-2.3.6-.6,0-1.2-.1-.8-1Z" fill="#f6ad46" stroke-width="0"/>
          <path d="M97.2,86.8c.6-1.2,1.8-.7,2.7-1,.6-.2,1.3,0,1.9-.4,2.3,2.2,4.5,4.5,6.8,6.7.6.6.6,1,.1,1.6-1.9,2.9-1.8,2.9-4.2.5-2.4-2.5-4.9-4.9-7.3-7.4Z" fill="#f99547" stroke-width="0"/>
          <path d="M21.2,33.3c2.7,2.7,5.5,5.3,8.2,8,.7.7.2,3.5-.8,3.9-.4.2-.6-.2-.9-.4-3.6-3.5-7.1-7.1-10.6-10.6-.3-.3-.7-.6,0-1,1.4-.9,2.8-.8,4.2,0Z" fill="#f6aa47" stroke-width="0"/>
          <path d="M97.5,18.8c.2.6-.3.8-.6,1.2-3,3.1-6.1,6.1-9,9.2-1.1,1.2-1.9,1.3-3,.3-.5-.4-1.1-.7-1.6-1.1,3.7-2.7,6.3-6.4,9.7-9.3,1.5-.8,3-.6,4.5-.3Z" fill="#f5b047" stroke-width="0"/>
          <path d="M22.2,26.2c.4.3.6.5.8.7,3.3,3.3,6.6,6.6,9.9,9.9,1.4,1.4-.3,1.9-.7,2.7-.6,1.1-1.1,0-1.4-.3-3.2-3.2-6.4-6.4-9.6-9.6-.4-.4-1.1-.7-.4-1.4.5-.6,1-1.2,1.6-1.9Z" fill="#f6ac46" stroke-width="0"/>
          <path d="M54.6,24.4l-1.8.4c-.4,0-.5-.2-.5-.5,0-5.3-.2-10.6.1-15.8,0,0,.2-.2.3-.2q3-.8,3,2.2c0,4.3,0,8.6,0,12.9-.3.4-.6.8-1,1Z" fill="#f4b545" stroke-width="0"/>
          <path d="M41.6,94.2c-.3.4-.6.6-.8.9-3.2,3.2-6.4,6.3-9.5,9.5-1.3,1.4-1.9.1-2.7-.5-1.1-.8,0-1.3.4-1.8,3.1-3.1,6.2-6.3,9.4-9.4.3-.3.6-.9,1.1-.4.7.5,1.3,1.1,2.1,1.6Z" fill="#fa9348" stroke-width="0"/>
          <path d="M17.4,69.8c-2.2,0-4.5,0-6.7,0-1.7,0-1.3-1.1-1.4-2-.2-1.2.5-1.2,1.4-1.2,4.4,0,8.8,0,13.3,0,2.1,0,1.4,1.6,1.6,2.5.3,1.2-1,.7-1.5.7-2.2,0-4.4,0-6.5,0Z" fill="#f89f47" stroke-width="0"/>
          <path d="M101.8,22.8c-.2.2-.3.4-.4.5-3.3,3.4-6.5,6.8-9.8,10.1-1.2,1.2-1.5-.4-2.2-.8-.8-.4-.6-.9,0-1.5,3.2-3.3,6.5-6.5,9.7-9.8.4-.4.8-.9,1.4-.4.5.5,1.2,1,1.5,1.8Z" fill="#f6ae46" stroke-width="0"/>
          <path d="M51.1,25.5c-1.3.5-2.7,1-3.9,1.6-.9.5-1.3.4-1.6-.6-1.3-4.5-2.7-8.9-4-13.3-.1-.4-.7-1,.1-1.3.8-.3,1.1.2,1.5.9,2.1,3.9,4.2,7.7,6.4,11.6.3.5.5,1,1.2.9.4-.1.5,0,.4.4Z" fill="#f5b146" stroke-width="0"/>
          <path d="M25.4,86c1.5.7,3.3,0,4.8.9-2.8,2.8-5.6,5.7-8.4,8.6-.7.7-1.1.6-1.6,0-1.9-2.3-1.9-2.3.2-4.4,1.7-1.6,3.4-3.3,5-4.9Z" fill="#fa9547" stroke-width="0"/>
          <path d="M25.9,52.4c-1.4-1.6-3.5-2.1-5.2-3.1-2.6-1.5-5.3-2.8-7.9-4.3-.5-.3-1.2-.4-.8-1.3.4-.8.9-.3,1.3-.2,4.3,1.3,8.7,2.6,13,3.9.9.3,1.3.5.8,1.5-.5,1.1-.8,2.3-1.3,3.4,0,0,0,0,0,0Z" fill="#f7a646" stroke-width="0"/>
          <path d="M33.4,26.9c1.6,1.5,3.2,3.1,4.8,4.6.6.5.6,1,0,1.4,0,0-.2.2-.3.2q-2.6,2.5-5.2,0c-1.7-1.7-3.4-3.4-5-5.1,0-1,.9-1,1.5-1.1,1.4-.2,2.8-.3,4.2,0Z" fill="#f6ad46" stroke-width="0"/>
          <path d="M102.9,50.3c-.5.4-.7,1.2-1.4,1.4-.2-.7-.5-1.3-.7-2-.3-1.4,0-2.4,1.5-2.9,2-.7,4.1-1.2,5.9-2.3,1.8-1.1,3.8-1.7,5.8-2.3.6-.2,1.1-.2,1.4.6.3.7,0,1-.5,1.3-4,2-7.8,4.4-11.9,6.2Z" fill="#f7a747" stroke-width="0"/>
          <path d="M96,97.4c1.4,1.4,2.9,2.8,4.4,4.1.6.6.6.9,0,1.5-2.9,2.7-2.8,2.7-5.6,0-1.6-1.6-3.1-3.2-4.7-4.8-.1-.5.2-.9.6-.9,1.8.2,3.6-.6,5.4.2Z" fill="#fa9147" stroke-width="0"/>
          <path d="M33.4,94.2c-2.1,2.2-4.2,4.4-6.3,6.6-.7.8-1.2,1-2.1,0-2.5-2.6-2.6-2.5,0-5,.9-.9,1.9-1.8,2.8-2.7,1.8-.5,3.6.4,5.4.2.4,0,.5.4.3.8Z" fill="#fa9347" stroke-width="0"/>
          <path d="M110.5,33c0,.6-.3.8-.6,1.1-2.2,2.2-4.4,4.4-6.6,6.6-.7.7-1.4.8-2,.2-.6-.7-1.4-1.8-.6-2.6,2.4-2.6,4.9-5,7.4-7.5.2-.2.5-.5.7-.2.6.8,1.2,1.6,1.7,2.3Z" fill="#f6ab46" stroke-width="0"/>
          <path d="M37.7,101.7c1,.9,2.2.8,3.3.5.8-.2,1.4,0,1.9.5-1.6,2.1-3.7,3.8-5.4,5.7-.9,1-1.5,1-2.6.3-2.6-1.7-2.7-1.7-.5-3.9,1.1-1.1,2.2-2.1,3.3-3.1Z" fill="#fa9047" stroke-width="0"/>
          <path d="M97.7,91.1c2.1,2.1,4.2,4.2,6.5,6.2,1.3,1.1.2,1.6-.3,2.4-.7,1-1.2.7-1.9,0-2.5-2.6-5.1-5.2-7.7-7.8.6-1.4,1.5-1.6,3.4-.8Z" fill="#fa9448" stroke-width="0"/>
          <path d="M60,23.4c-1.1,0-1.6-.3-1.5-1.5,0-2.9,0-5.9,0-8.8,0-3.2-.4-2.8,2.8-2.8,0,4,0,8,0,12,0,1.1-.5,1.3-1.4,1.2Z" fill="#f5b145" stroke-width="0"/>
          <path d="M96,97.4c-1.7,0-3.4.8-5.2.1-.3-.1-.6.3-.8.6-.4-.4-.9-.8-1.3-1.2q-3-3,.6-5.4c.5-.3.8-.4,1.1,0,1.8,2,3.9,3.7,5.6,5.8Z" fill="#fa9347" stroke-width="0"/>
          <path d="M33.4,94.2c0-.3-.2-.6-.5-.5-1.8.6-3.4-.2-5.1-.5,1.8-1.8,3.5-3.6,5.3-5.4.1-.1.3-.3.4-.4,1.8,0,2,1.8,3.2,2.6.6.4.5.8,0,1.3-1.1.9-1.9,2.2-3.2,3Z" fill="#fa9547" stroke-width="0"/>
          <path d="M25.6,54.6c0,1.6-.4,2.3-2.2,2.2-4.1-.1-8.1,0-12.2,0-2.2,0-2.2,0-1.5-2.2,5.3-.2,10.6-.3,16,0Z" fill="#f7a54a" stroke-width="0"/>
          <path d="M33.4,26.9c-.9.6-1.9.3-2.9.3-1,0-2,0-2.8.8-.7-.7-1.5-1.3-2.2-2.1-.5-.6-1.8-1-1.3-1.8.7-1.2,1.9-2.2,3.2-3,.3-.2.5.2.7.4,1.8,1.8,3.5,3.6,5.3,5.4Z" fill="#f5af46" stroke-width="0"/>
          <path d="M78,104.5c1.7.2,3.4.3,5,1,.7,2.1,1.3,4.2,2,6.2.3.8-.1,1.1-.8,1.2-.6.1-1.3,1-1.8,0-1.5-2.8-2.9-5.6-4.4-8.5Z" fill="#fb8f48" stroke-width="0"/>
          <path d="M71.7,107c1.2.7,2.3.3,3.5,0,.4,2.8.3,5.6,0,8.4-.3.2-.6.4-.9.5-2.5.8-2.7.6-2.7-1.9,0-2.3,0-4.7,0-7Z" fill="#fb8c47" stroke-width="0"/>
          <path d="M41.8,23.5c-2.3-2.3-4.6-4.6-6.9-6.8-.8-.7-.8-1,.2-1.6,3.8-2.3,3.7-2.4,5,1.8.6,2,1.2,3.9,1.7,5.9,0,.2,0,.3,0,.7Z" fill="#f5b246" stroke-width="0"/>
          <path d="M49.6,19.1c-1.5-2.8-2.9-5.4-4.3-8.1-.2-.4-.2-.7.3-.8.1,0,.2,0,.3-.1,3.8-1.7,4.1-1.6,4.1,2.5,0,2,0,4,0,6,0,0-.1.2-.3.5Z" fill="#f5b74b" stroke-width="0"/>
          <path d="M19.7,52c-2.9,0-5.8,0-8.8,0,0,0-.1,0-.2,0-.4,0-.8-.1-.7-.6.2-1.4.4-2.8,1-4.1.2-.4.6,0,.9,0,2.6,1.4,5.2,2.8,7.8,4.2,0,.1,0,.3,0,.4Z" fill="#f7a84b" stroke-width="0"/>
          <path d="M15.3,36.3c2.4,2.4,4.8,4.8,7.5,7.4-2.3-.7-4.3-1.3-6.3-1.9q-3.5-1.1-1.8-4.3c.2-.5.5-.9.7-1.2Z" fill="#f6a946" stroke-width="0"/>
          <path d="M38.3,23.7c1.6,1.5,3.1,3,4.7,4.5.5.5.5.7,0,1.2-1.9,1.5-1.9,1.5-3.7-.2-1.8-1.8-3.5-3.6-5.3-5.4,1.4-1.1,2.9-.6,4.4,0Z" fill="#f5af46" stroke-width="0"/>
          <path d="M85.8,22.1c.8-2.7,1.7-5.5,2.5-8.3.2-.8.6-.8,1.2-.4,0,0,.1,0,.2,0,3.7,1.8,3.7,1.8.9,4.7-1.4,1.4-2.7,2.9-4,4.4l-.6-.5Z" fill="#f5b347" stroke-width="0"/>
          <path d="M77.1,18.1c0-2.8,0-5.5,0-8.3,0-.4,0-1,.5-.9,1.3.3,2.6.7,3.9,1.1.4.1.2.5,0,.7-1.3,2.5-2.7,5-4.1,7.6-.1,0-.3-.1-.4-.2Z" fill="#f5b74b" stroke-width="0"/>
          <path d="M38.3,23.7c-1.5.3-2.9-.3-4.4,0-1.2-1.2-2.5-2.3-3.6-3.5-.4-.4-1.4-.8-.3-1.4.8-.4,1.4-2.2,2.7-.8,1.9,1.9,3.8,3.8,5.6,5.8Z" fill="#f5b146" stroke-width="0"/>
          <path d="M108.6,50.2c2.5-1.3,4.8-2.6,7.1-3.8.5-.3.7-.3,1,.4,1.3,3.3,1.1,3.7-2.4,3.7-1.8,0-3.5,0-5.3,0,0,0,0,0-.4-.3Z" fill="#f7a94e" stroke-width="0"/>
          <path d="M67.3,100.9c1.1,0,1.6.3,1.5,1.5,0,1.3-.1,2.7,0,4,.2,1.6-.4,2.2-2,1.9-.3,0-.6,0-.9,0,0-2.1,0-4.2,0-6.3,0-1.1.5-1.3,1.4-1.2Z" fill="#fa9147" stroke-width="0"/>
          <path d="M105.6,41.9c2-2,3.9-3.8,5.7-5.6.6-.6.9-.4,1.3.2,1.7,3,1.7,2.9-1.6,4-1.6.5-3.3,1-4.9,1.5,0,0-.1,0-.5,0Z" fill="#f6ab48" stroke-width="0"/>
          <path d="M84.1,18.8c.2-2.5-1.7-1.6-3-1.9.9-1.7,1.8-3.4,2.7-5.1.3-.7.7-.8,1.4-.5.6.3,1.3.4.9,1.3-.7,2-1.3,4.1-2,6.2Z" fill="#f5b347" stroke-width="0"/>
          <path d="M97.7,91.1c-1.1.3-2.6-.7-3.4.8-.5-.5-1-1-1.5-1.4-1-.8-.8-1.4,0-2.2.8-.9,1.4-1.2,2.3,0,.8,1,1.8,2,2.7,2.9Z" fill="#fa9648" stroke-width="0"/>
          <path d="M110.3,78c1.7,0,3.4,0,5.1,0,.3,0,.9,0,.9.4-.1.8-.4,1.6-.7,2.4-.2.5-.6.3-.9,0-1.5-.8-3-1.6-4.5-2.4v-.4Z" fill="#f9a255" stroke-width="0"/>
          <path d="M17.3,78.8c-1.3.7-2.6,1.5-4,2.2-.3.2-.7.5-.9,0-.3-.7-.6-1.5-.7-2.3,0-.4.5-.4.9-.4,1.6,0,3.1,0,4.7,0,0,.1,0,.3,0,.4Z" fill="#f9a459" stroke-width="0"/>
          <path d="M93.9,34.7c0,.7.5.9,1.1.5.7-.5,1.4-.1,2-.1-.5,1.4-1,2.7-1.5,4.3-.9-1.1-1.7-2-2.5-2.9-.8-1.1.5-1.3.9-1.8Z" fill="#f6ac47" stroke-width="0"/>
          <path d="M20.7,87c-1.2,1.2-2.2,2.5-3.5,3.4-.6.4-.9-1.1-1.4-1.7-.3-.4-.2-.7.3-.9,1.3-.4,2.6-.8,3.8-1.2.4-.1.7,0,.7.4Z" fill="#fa9a4e" stroke-width="0"/>
          <path d="M107,86.4c1.4.4,2.8.8,4.2,1.2.3,0,.9.2.7.6-.3.8-.7,1.5-1.2,2.1-.2.3-.6-.2-.8-.4-1.1-1-2.2-2.1-3.3-3.1.1-.1.2-.3.4-.4Z" fill="#f99849" stroke-width="0"/>
          <path d="M77.7,110.1c1.1,1,1.3,2.1,1.8,2.8.9,1.3.4,1.7-.9,1.9-.7.1-1,0-1-.7,0-1.2,0-2.4,0-4Z" fill="#fb9c61" stroke-width="0"/>
          <path d="M38.9,110.9c1-1.1,2-2.1,3.2-3.4,0,1.6-.3,3-1,4-.7.9-1.5-.4-2.2-.6Z" fill="#fb914e" stroke-width="0"/>
          <path d="M86.7,106.8c.7.8,1.3,1.6,2,2.4.3.4,1.1.8.8,1.2-.4.5-1.1.9-1.8,1-.7.1-.6-.7-.7-1.2-.4-1-.6-2-.9-3,.2-.1.4-.3.6-.4Z" fill="#fb924d" stroke-width="0"/>
          <path d="M51.1,25.5c-.1-.1-.3-.2-.4-.4,1.4-.3,1.6-1.1,1.6-2.4,0-4.1,0-8.2,0-12.3,0-.6-.2-1.3.3-1.9,0,4.8,0,9.7,0,14.5,0,.6-.2,1.3.3,1.8-.5.4-1.2.6-1.8.6Z" fill="#f7c06c" stroke-width="0"/>
          <path d="M50,110.8c0,1,0,2,0,2.9,0,.4.2,1.1-.5,1.2-.5,0-1.1-.1-1.5-.4-.6-.4,0-.8.1-1.2.4-.9.9-1.7,1.4-2.6.1,0,.3,0,.4,0Z" fill="#fb9e64" stroke-width="0"/>
          <path d="M25.6,54.6c-5.3,0-10.6,0-16,0,.5-.5,1.2-.3,1.8-.3,3.9,0,7.7,0,11.6,0,1.5,0,2.6-.2,2.9-1.8,0,0,0,0,0,0,0,.7,0,1.5-.4,2.1Z" fill="#f9c07f" stroke-width="0"/>
          <path d="M97.5,18.8c-1.5.7-3,.2-4.5.3.2-.2.3-.4.5-.5,2-2,2-2,4.1.2Z" fill="#f5b248" stroke-width="0"/>
          <path d="M21.2,33.3c-1.4,0-2.8-.5-4.2,0,1.8-2.7,1-3.6,4.2,0Z" fill="#f6ad48" stroke-width="0"/>
          <path d="M71.6,7.8c0,5.4,0,10.8,0,16.2-.4-.2-.3-.6-.3-.9,0-4.8,0-9.6,0-14.4,0-.3,0-.7.3-.9Z" fill="#f5b74c" stroke-width="0"/>
          <path d="M55.7,102c0-.6,0-1.2,0-1.7,0,0,.1,0,.1.1.3,5.2.3,10.4,0,15.7,0,0,0,0-.1.1,0-4.7,0-9.4,0-14.1Z" fill="#fb914e" stroke-width="0"/>
          <path d="M75,24.1c-.8-.4-.5-1.1-.5-1.7,0-4.7,0-9.4,0-14.1.5.6.2,1.4.2,2,0,4,0,7.9,0,11.9,0,.6,0,1.3.2,1.9Z" fill="#f5b74c" stroke-width="0"/>
          <path d="M75.2,115.4c0-2.8,0-5.6,0-8.4.4-.8-.8-2.4,1.1-2.5-.9.3-.8,1.1-.9,1.9-.1,3,.3,6-.2,9Z" fill="#fb914a" stroke-width="0"/>
          <path d="M52.2,105.2c.6,1.9.2,3.9.3,5.9,0,1.5,0,2.9,0,4.4-.5-2.7,0-5.5-.2-8.2,0-.7,0-1.4,0-2.1Z" fill="#fb914e" stroke-width="0"/>
          <path d="M54.6,24.4c.3-.4.6-.8,1-1,.1.8-.4,1-1,1Z" fill="#f5b449" stroke-width="0"/>
          <path d="M54.9,46.8c.7,0,1.4,0,2.1,0,2,.2,2.9,1.1,3.3,3.1,1,5.2-.7,9.6-4.5,13.1-4.4,4-9.9,5.6-15.8,5.7-2.5,0-4.2-1.2-5.7-3.1-1.8-2.3-3-4.9-3.7-7.8-.6-2.4.2-4.1,2.1-5.5,2.6-1.9,5.6-3,8.7-3.7,4.4-1,8.8-1.6,13.3-1.8Z" fill="#000" stroke-width="0"/>
          <path d="M75.2,79c-5.1,6.7-13,7.3-18.3,4.9-3.7-1.6-6.2-4.4-7.3-8.3-.2-.8-.2-1.2.8-1.2,8.3-.7,16.6-1.4,24.8-2,2.1-.2,2.2-.1,1.8,2-.2,1-.5,1.9-.8,2.9-.6.7-1.4.5-2.1.6-1.7,0-3.4,0-5.1.2,1.5.3,2.9.1,4.4.2.7,0,1.6-.2,1.8.8Z" fill="#fefefe" stroke-width="0"/>
          <path d="M75.2,79c-1.7-.8-3.4-.2-5.1-.3-.4,0-.8,0-1.2,0-.6,0-1.3.1-1.3-.8,0-.7.7-.6,1.2-.6,2.5,0,4.9,0,7.4,0,2.2,2-.7,1.2-1,1.8Z" fill="#fd9a54" stroke-width="0"/>
        </g>
        <g>
          <path d="M177.6,73.2c0-1.9-.7-3.4-2.1-4.5s-3.9-2.1-7.4-3.3c-3.6-1.2-6.4-2.3-8.5-3.4-5.7-3.1-8.5-7.2-8.5-12.4s.8-5.1,2.3-7.2c1.5-2.1,3.7-3.8,6.6-5,2.9-1.2,6.1-1.8,9.6-1.8s6.8.6,9.6,1.9c2.8,1.3,5,3.1,6.5,5.5,1.5,2.4,2.3,5,2.3,8h-10.3c0-2.3-.7-4.1-2.2-5.3s-3.5-1.9-6.1-1.9-4.5.5-5.9,1.6-2.1,2.5-2.1,4.2.8,3,2.5,4.1c1.6,1.1,4.1,2.1,7.2,3.1,5.9,1.8,10.1,4,12.8,6.6,2.7,2.6,4,5.9,4,9.8s-1.6,7.7-4.9,10.2-7.7,3.7-13.2,3.7-7.4-.7-10.5-2.1c-3.2-1.4-5.6-3.3-7.2-5.8-1.7-2.5-2.5-5.3-2.5-8.5h10.3c0,5.5,3.3,8.3,9.9,8.3s4.4-.5,5.7-1.5,2.1-2.4,2.1-4.2Z" fill="#000" stroke-width="0"/>
          <path d="M216.6,82.6c-2.5,3-5.8,4.5-10.2,4.5s-7-1.1-9.1-3.4-3.2-5.6-3.2-10.1v-24.4h9.9v24.1c0,3.9,1.8,5.8,5.3,5.8s5.7-1.2,6.9-3.5v-26.4h10v37.2h-9.4l-.3-3.8Z" fill="#000" stroke-width="0"/>
          <path d="M242.8,49.2l.3,4.3c2.7-3.3,6.2-5,10.7-5s6.9,1.2,8.8,3.5c1.9,2.3,2.9,5.8,3,10.4v24h-9.9v-23.8c0-2.1-.5-3.6-1.4-4.6-.9-1-2.4-1.4-4.6-1.4s-4.9,1.2-6.3,3.6v26.2h-9.9v-37.2h9.4Z" fill="#000" stroke-width="0"/>
          <path d="M299.7,73.2c0-1.9-.7-3.4-2.1-4.5s-3.9-2.1-7.4-3.3c-3.6-1.2-6.4-2.3-8.5-3.4-5.7-3.1-8.5-7.2-8.5-12.4s.8-5.1,2.3-7.2c1.5-2.1,3.7-3.8,6.6-5,2.9-1.2,6.1-1.8,9.6-1.8s6.8.6,9.6,1.9c2.8,1.3,5,3.1,6.5,5.5,1.5,2.4,2.3,5,2.3,8h-10.3c0-2.3-.7-4.1-2.2-5.3-1.4-1.3-3.5-1.9-6.1-1.9s-4.5.5-5.9,1.6c-1.4,1.1-2.1,2.5-2.1,4.2s.8,3,2.5,4.1c1.6,1.1,4.1,2.1,7.2,3.1,5.9,1.8,10.1,4,12.8,6.6,2.7,2.6,4,5.9,4,9.8s-1.6,7.7-4.9,10.2-7.7,3.7-13.2,3.7-7.4-.7-10.5-2.1c-3.2-1.4-5.6-3.3-7.2-5.8-1.7-2.5-2.5-5.3-2.5-8.5h10.3c0,5.5,3.3,8.3,9.9,8.3s4.4-.5,5.7-1.5,2.1-2.4,2.1-4.2Z" fill="#000" stroke-width="0"/>
          <path d="M348.8,72.9l4.9-23.7h9.6l-9.5,37.2h-8.3l-7-23.4-7,23.4h-8.3l-9.5-37.2h9.6l4.8,23.7,6.8-23.7h7.2l6.8,23.7Z" fill="#000" stroke-width="0"/>
          <path d="M389.7,86.4c-.5-.9-.8-2-1-3.3-2.4,2.7-5.5,4-9.4,4s-6.7-1.1-9.1-3.2c-2.4-2.1-3.6-4.8-3.6-8s1.5-7,4.4-9.1,7.1-3.2,12.7-3.2h4.6v-2.1c0-1.7-.4-3.1-1.3-4.1-.9-1-2.3-1.5-4.2-1.5s-3,.4-3.9,1.2-1.4,1.9-1.4,3.3h-9.9c0-2.2.7-4.1,2-6,1.3-1.8,3.2-3.3,5.6-4.3,2.4-1,5.2-1.6,8.2-1.6,4.6,0,8.2,1.2,10.9,3.5,2.7,2.3,4,5.5,4,9.7v16.1c0,3.5.5,6.2,1.5,8v.6h-10ZM381.5,79.5c1.5,0,2.8-.3,4.1-1s2.2-1.5,2.8-2.6v-6.4h-3.7c-5,0-7.6,1.7-7.9,5.2v.6c0,1.2.4,2.3,1.3,3.1.9.8,2.1,1.2,3.6,1.2Z" fill="#000" stroke-width="0"/>
          <path d="M439.4,68.1c0,5.7-1.3,10.3-3.9,13.8-2.6,3.4-6.1,5.2-10.5,5.2s-6.8-1.3-9.1-3.9v17.5h-9.9v-51.5h9.2l.3,3.6c2.4-2.9,5.5-4.3,9.4-4.3s8.1,1.7,10.7,5.1c2.5,3.4,3.8,8.1,3.8,14v.5ZM429.4,67.4c0-3.5-.6-6.1-1.8-8-1.2-1.9-3-2.8-5.3-2.8s-5.3,1.2-6.4,3.6v15.2c1.2,2.5,3.4,3.7,6.5,3.7,4.7,0,7.1-3.9,7.1-11.7Z" fill="#000" stroke-width="0"/>
        </g>
      </svg>
    )
  }

  const bbsClick = () => {
    window.open('https://www.memechicks.com/bigblackswap', '_blank', 'noopener,noreferrer');
  }
  const btClick = () => {
    window.open('https://www.babyturuswap.me', '_blank', 'noopener,noreferrer');
  }
  const csClick = () => {
    window.open('https://www.chickenswap.com/', '_blank', 'noopener,noreferrer');
  }
  const ssClick = () => {
    window.open('https://sunswap.com/#/home', '_blank', 'noopener,noreferrer');
  }

  return (
    <div id='wheretobuy' className="buy">
      <h2 data-aos="fade-up" data-aos-delay="0" >Where to buy</h2>
      <div className='buy-row'>
        <div data-aos="fade-up" data-aos-delay="100">
          <div onClick={bbsClick} className='bbs'>
            {bbs()}
          </div>          
        </div>
        <div data-aos="fade-up" data-aos-delay="200">
          <div onClick={btClick} className='bt'>
            <img src={bt} alt=''/>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-delay="300">
          <div onClick={csClick}  className='cs'>
            {cs()}
          </div>
        </div>
        <div data-aos="fade-up" data-aos-delay="400">
          <div onClick={ssClick} className='ss'>
            {sun()}
          </div>
        </div>





      </div>
    </div>
  );
}                         

export default Buy;
 