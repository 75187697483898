import React from 'react';
import './style.css';
import tronguy from './img/tronguy.png'
import Social from '../social/Social';
import StickyNavbar from "react-sticky-navbar";

function Navigation() {
  return (
    <div className='sticky-nav'>
      <StickyNavbar
        showOnTop={true}
        showOnScrollDown={false}
        showOnScrollUp={false}
        showOnBottom={false}
        duration={600}
      >
        <div className="navigation">
          <div className='logo'>
            <img src={tronguy} alt=''/>
          </div>
          <div className='top-menu'>
            <ul>
              <li><a href='#wheretobuy'>Where to buy</a></li>
              <li><a href='#tokenomics'>Tokenomics</a></li>
              <li><a href='#tronguy'>Tron Guy</a></li>
              <li><a href='#whilepaper'>Whitepaper</a></li>
            </ul>
          </div>
          <Social/>
        </div>      
      </StickyNavbar>      
    </div>
  );
}                         

export default Navigation;
