import React from 'react';
import './style.css';
import milestones_desktop from './img/milestones-desktop.png'
import milestones_mobile from './img/milestones-mobile.png'

function Milestones({wideScreen}) {
  return (
    <div className="milestones">
      <h2 data-aos="fade-up" data-aos-delay="0">
        TRON GUY Roadmap:<br/>
        <span>Development Milestones</span>
      </h2>
      <div className='img'>
        {wideScreen < 1441 && wideScreen > 700 
          ? (<img src={
            wideScreen < 1000
            ? milestones_mobile 
            : milestones_desktop
          } alt='' style={{width: '100%'}}/>) 
          : ''}
      </div>
    </div>
  );
}                         

export default Milestones;
 