import React from 'react';
import './style.css';
import Social from '../social/Social';

function Footer() {

  return (
    <div className="footer">
      <div className='footer-box'>
        <span>{new Date().getFullYear()} by Tron Guy Project</span>
        <Social/>
      </div>
    </div>
  );
}                         

export default Footer;
 