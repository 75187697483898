import React from 'react';
import './style.css';
import tg from './img/tg.png'
import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import icon3 from './img/icon3.png'
import icon4 from './img/icon4.png'
import yt from './img/yt.png'

function History() {
  const tgDrowing = () => {
    window.open('https://video.wixstatic.com/video/d6f1b2_75eeb89f5aab48f9a56ba0a5c18557f4/720p/mp4/file.mp4', '_blank', 'noopener,noreferrer');
  }
  const jimmyKimmelLive = () => {
    window.open('https://www.youtube.com/watch?v=c1xaBMUq8jI&t=27s&ab_channel=CarolWade', '_blank', 'noopener,noreferrer');
  }
  const southPark = () => {
    window.open('https://www.youtube.com/watch?v=Oc-9xNbvRqg&t=44s&ab_channel=SouthParkStudios', '_blank', 'noopener,noreferrer');
  }
  const interView = () => {
    window.open('https://www.youtube.com/watch?v=S6YPxdTHR7E&t=518s&ab_channel=KnowYourMeme', '_blank', 'noopener,noreferrer');
  }
  return (
    <>
      <div id='tronguy' className="history">
        <div className='history-box'>
          <h2 data-aos="fade-up" data-aos-delay="0">Tron Guy</h2>
          <div className='tg-grid'>
            <div>
              <p className='lead' data-aos="fade-up" data-aos-delay="100">
                In the digital expanse of the Tron blockchain, a new light emerges, beckoning users from all corners of the grid.
              </p>
              <p data-aos="fade-up" data-aos-delay="200" style={{marginBottom: '40px'}}>
                Drawing inspiration from the iconic "Tron" movie, a saga that championed the individual's power against centralized control, 
                we find a parallel narrative in the creation of the Tron Guy meme coin. Just as the heroes of "Tron" fought for the autonomy 
                and freedom of the digital realm, so too does the Tron Guy meme coin strive to empower its community, offering a beacon of 
                unity and prosperity in the vast crypto universe.<br/>
                <br/>
                The Tron blockchain, with its vision of decentralization and creating a truly liberated digital space, mirrors the foundational 
                battles fought within the "Tron" universe. Yet, despite its innovative technology and potential, the voices of individual 
                investors and community members have often felt drowned out by the overarching narrative of institutional growth and expansion.<br/>
                <br/>
                It is here, at this juncture of digital evolution and community aspiration, that the Tron Guy meme emerges as not just a symbol, 
                but as a rallying cry for the users, by the users. Tron Guy, an internet legend who donned the digital armor of the Tron universe, 
                embodies the spirit of every user within the blockchain space—bold, imaginative, and unwavering in the face of uncertainty.<br/>
              </p>
            </div>
            <div className='tg-box'>
              <img src={tg} alt=''/>
            </div>
          </div>
        </div>
      </div>
      <div className='history-info'>
        <div className='history-info-grid'>
          <div className='history-info-column' data-aos="fade-up" data-aos-delay="300">
            <div className='yt-row'>
              <div className='yt-link'>
                <img onClick={tgDrowing} className='ytIcon' src={yt} alt=''/>
                <img src={icon1} alt=''/>                
              </div>
              <div className='yt-link'>
                <img onClick={jimmyKimmelLive} className='ytIcon' src={yt} alt=''/>
                <img src={icon2} alt=''/>                
              </div>
            </div>
            <div>
              <p>
                His transformation from a fan to an icon parallels the journey of every investor and participant in the Tron ecosystem. 
                Just as Tron Guy brought the digital frontier to life through his costume, we aim to bring the essence of the Tron blockchain 
                into the spotlight through the Tron Guy meme coin. 
                <br/><br/>
                This meme coin is not just a token; it's a statement. It declares that in a world of digital behemoths, the individual's voice 
                can still hold power and influence. It acknowledges that while the battle for a decentralized future is fraught with challenges, 
                there is joy, camaraderie, and strength in standing together as a community.                 
              </p>
            </div>
          </div>
          <div className='history-info-column' data-aos="fade-up" data-aos-delay="400">
            <div>
              <p>
                The Tron Guy meme coin is a bridge connecting the legacy of the "Tron" film—a testament to innovation, resistance, and the fight for 
                the user—to the modern blockchain movement. We are creating the Tron Guy meme to remind the world that the essence of the blockchain 
                is not in the cold, hard lines of code, but in the vibrant, dynamic community that gives it life.
                <br/><br/>
                It's a call to unite, invest in our collective digital destiny, and shape a future where the grid truly belongs to its users. 
                Join us in this journey, as we fight for the users, echoing the timeless message of the "Tron" saga, and weaving a new narrative of 
                empowerment and unity on the Tron blockchain.
              </p>
            </div>
            <div className='yt-row'>
              <div className='yt-link'>
                <img onClick={southPark} className='ytIcon' src={yt} alt=''/>
                <img src={icon3} alt=''/>
              </div>
              <div className='yt-link'>
                <img onClick={interView} className='ytIcon' src={yt} alt=''/>
                <img src={icon4} alt=''/>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </>

  );
}                         

export default History;
 