import React from 'react';
import './style.css';
import chart from './img/chart.png'
import Button from '../../components/button/Button';

function Tokenomics() {
  const avedex = () => {
    window.open('https://avedex.cc/token/THvr8Jw1PqFSXAhYQgQSuGfqr3nWGy334j-tron?from=Default', '_blank', 'noopener,noreferrer');
  }
  return (
    <div id='tokenomics' className="tokenomics">
      <div className='tokenomics-box'>
        <h2 data-aos="fade-up" data-aos-delay="0">Tokenomics</h2>
        <div className='toke-content'>
          <div className='toke-row-4' data-aos="fade-up" data-aos-delay="100">
            <div className='toke-item'>
              <div className='toke-item-title'>Supply</div>
              <div>33 Billion tokens</div>
            </div>
            <div className='toke-item'>
              <div className='toke-item-title'>Digits</div>
              <div>18</div>
            </div>
            <div className='toke-item'>
              <div className='toke-item-title'>Name</div>
              <div>$TRNGUY (TRNGuy)</div>
            </div>
            <div className='toke-item'>
              <div className='toke-item-title'>Address</div>
              <div className='mini'>THvr8Jw1PqFSXAhYQgQSuGfqr3nWGy334j</div>
            </div>
          </div>
          <div  data-aos="fade-up" data-aos-delay="200">
            <h3>Token allocation:</h3>
            <div className='toke-row-3'>
              <div className='toke-item'>10% Marketing</div>
              <div className='toke-item'>2% Jay</div>
              <div className='toke-item'>Stealth Launch</div>              
            </div>
          </div>
          <div className='chart' data-aos="fade-up" data-aos-delay="300">
            <img src={chart} alt=''/>
          </div>
          <div className='btn-row' data-aos="fade-up" data-aos-delay="320">
            <div onClick={avedex}>
              <Button text={'Check real live price action'}/> 
            </div>
          </div>        
        </div>
      </div>
    </div>
  );
}                         

export default Tokenomics;
 