import React from 'react';
import './style.css';
import icon1 from './img/icon1.png'

function Wallet() {
  return (
    <div className="wallet">
      <div className='wallet-box'>
        <div className='wallet-item' data-aos="fade-up" data-aos-delay={window.innerWidth < 840 ? '200' : '100'}>
          <div className='wallet-item-name'>Jay Maynard (Tron Guy) allocation wallet:</div>
          <div className='wallet-item-value' style={{color: '#60d5e7'}}>
            660.000.000
          </div>
          <div className='wallet-item-coin' style={{color: '#60d5e7'}}>$TRNGUY</div>
        </div>
        <div className='icon' data-aos="fade-up" data-aos-delay={window.innerWidth < 840 ? '100' : '200'}>
          <img src={icon1} alt=''/>
        </div>
        <div className='wallet-item' data-aos="fade-up" data-aos-delay={window.innerWidth < 840 ? "300" : "300"}>
          <div className='wallet-item-name'>Jay Maynard (Tron Guy) bucket list wallet:</div>
          <div className='wallet-item-value' style={{color: "#f6c21e"}}>
            330.000.000
          </div>
          <div className='wallet-item-coin' style={{color: "#f6c21e"}}>$TRNGUY</div>
        </div>
      </div>
      <div className='wallet-info'>
        <div data-aos="fade-up" data-aos-delay="100">
          <div style={{marginBottom: '30px'}}>
            <h3>TRON GUY Partnership</h3>
            <p>
              We are thrilled to announce our official partnership with Jay Maynard, famously known
              as TRON GUY. As an iconic figure in the tech community, Jay's enthusiasm and passion
              for innovative technology align perfectly with our mission. To commemorate this partnership 
              and empower Jay's involvement in our project, we have allocated two special wallets 
              with TRON GUY tokens.
            </p>
          </div>
          <div>
            <h3>Jay's Wallet</h3>
            <p>
              This wallet holds 660,000,000 $TRNGUY tokens, symbolizing Jay Maynard's direct
              stake in the project. It highlights his pivotal role and ongoing support in our journey to
              revolutionize the crypto space.
            </p>            
          </div>
        </div>
        <div data-aos="fade-up" data-aos-delay="300">
          <h3>Milestone Wallet</h3>
          <p>
            We have also set up a second wallet containing 330,000,000 $TRNGUY tokens. These tokens
            are earmarked to fund specific milestones and initiatives led by Jay, ensuring continuous growth
            and exciting developments for our community.<br/>
            <br/>
            With Jay Maynard as our partner, we're not only celebrating his legacy but also paving the way
            for a future filled with innovation and success. Stay tuned for more updates on Jay's activities
            and the milestones we achieve together.
          </p>
        </div>
      </div>
    </div>
  );
}                         

export default Wallet;
 