import React from 'react';
import './style.css';
import shop from './img/shop.png'
import shopMobile from './img/shop-mobile.png'
import znaczki  from './img/znaczki.png'
import tg  from './img/tg.png'
import Button from '../../components/button/Button';

function Shop({wideScreen}) {
  const goToShop = () => {
    window.open('https://degenmerch.store/product-category/tronguy/', '_blank', 'noopener,noreferrer');
  }
  return (
    <div className="shop">
      <div className='shop-box'>
        <h2 data-aos="fade-up" data-aos-delay="0">Shop</h2>
        <p data-aos="fade-up" data-aos-delay="100">
          Check our Tron Guy section in the DegenMerch.<br/>
          Store and pay with $TRNGUY or any major crypto tokens.
        </p>
        <div className='shop-baner' data-aos="fade-up" data-aos-delay="200">
          <img className='znaczki' src={znaczki} alt=''/>
          {wideScreen < 750 ? <img src={shopMobile} alt='' style={{borderRadius: '20px'}}/> : <img src={shop} alt=''/>}
          <img className='tg' src={tg} alt='' data-aos="fade-up" data-aos-delay="300"/>
        </div>
      </div>
      <div className='shop-btn-row' data-aos="fade-up" data-aos-delay="400">
        <div onClick={goToShop}>
          <Button text={'Visit Shop'}/>           
        </div>
      </div> 
    </div>
  );
}                         

export default Shop;
 