import React from 'react';
import './style.css';
import { GoArrowRight } from "react-icons/go";

function Button({text}) {

  return (
    <button className='button'>
      {text}
      <div style={{width: '40px', height: '28px'}}>
        <GoArrowRight fontSize={30}/>
      </div>
      
    </button>
  );
}                         

export default Button;
 