import React from 'react';
import './style.css';
import emailjs from 'emailjs-com';
import toast, { Toaster } from 'react-hot-toast';
import Button from '../../components/button/Button';

function Contact() {

  return (
    <div className="contact">
      <div className='contact-box'>
        <h2 data-aos="fade-up" data-aos-delay="0" style={{textAlign: 'center'}}>Contact us</h2>
        <p style={{textAlign: 'center', padding: '8px 0'}} data-aos="fade-up" data-aos-delay="100">
          For any support, Please mail to info@tronguy.io, Our support team will get back to you within 24 hours.
        </p>
        <form className='form'>
          <input type='text' name='name' placeholder='Full Name' data-aos="fade-up" data-aos-delay="200"/>
          <div className='form-row filed'>
            <input type='text' name='email' placeholder='Email Address' data-aos="fade-up" data-aos-delay="300"/>
            <input type='text' name='phone' placeholder='Phone Number' data-aos="fade-up" data-aos-delay="400"/>   
          </div>
          <textarea name='message' data-aos="fade-up" data-aos-delay="500"/>
          <div className='form-row' data-aos="fade-up" data-aos-delay="600" style={{gap: '10px'}}>
            <input type='checkbox' name='checkbox' style={{position: 'relative', top: '-9px'}}/>
            <p style={{fontSize: '14px'}}>
              The contact form is intended for inquiries and feedback. Please provide accurate contact details 
              and a brief message. All information submitted will be kept confidential and used solely for communication purposes. 
              We aim to respond to all inquiries within 48 hours. By submitting the form, you consent to our use of your 
              information as outlined in this policy. We take reasonable measures to protect your information from unauthorized access. 
              Thank you for contacting us.
            </p>
          </div>
          <div className='btn-row' data-aos="fade-up" data-aos-delay="700">
            <Button text={'Submit'}/>
          </div>
        </form>
      </div>
    </div>
  );
}                         

export default Contact;
 