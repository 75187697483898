import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import cover from './img/cover.png'
import play from './img/play.png'
import disc from './img/disc.png'
import './style.css';

function HowBuy({wideScreen}) {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  
  return (
    <div className="how-buy">

      <img className='disc' src={disc} alt='' data-aos="fade-right" data-aos-delay='1000'/>

      <div className='how-buy-box'>
        <div className='instruction'>
          <h2 data-aos="fade-up">How to Buy</h2>
          <div className='steps'>
            <div className='step' data-aos="fade-up" data-aos-delay='100'>
              <div className='steps-title'>STEP 1</div>
              <p>Install the TronLink wallet from the IOS/Android store</p>
            </div>
            <div className='step' data-aos="fade-up" data-aos-delay='200'>
              <div className='steps-title'>STEP 2</div>
              <p>Buy $TRX from any CEX and then send it to the Tronlink wallet</p>
            </div>
            <div className='step' data-aos="fade-up" data-aos-delay='300'>
              <div className='steps-title'>STEP 3</div>
              <p>Pick one of the DEX ChickenSWAP, BabyTuruSWAP, BBCSwap, or Sunswap and open it in your wallet's browser.</p>
            </div>
            <div className='step' data-aos="fade-up" data-aos-delay='400'>
              <div className='steps-title'>STEP 4</div>
              <p>Connect your wallet and buy the preferred amount. Tron Guy Contract address: <a href='adres'>THvr8Jw1PqFSXAhYQgQSuGfqr3nWGy334j</a></p>
            </div>
          </div>
        </div>
        <div className='video'>
          <div className='video-title'>
            <div data-aos="fade-left" data-aos-delay='600'>Watch the video instructions:</div>
          </div>
          <div className='video-box' data-aos="fade-up" data-aos-delay='500'>
            {!isPlaying && (
              <div className='cover' onClick={handlePlay}>
                <img src={cover} alt='Cover'/>
                <button className='play-button'>
                  <img src={play} alt='' />
                </button>
              </div>
            )}
            <ReactPlayer
              url='https://video.wixstatic.com/video/e335d8_91c985cb88014d0082de97bc4bba0f96/720p/mp4/file.mp4'
              playing={isPlaying}
              controls={isPlaying && true}
              width={wideScreen < 473 ? '100%' : '376px'}
              height={wideScreen < 473 ? '100%' : '100%'}
              style={{borderRadius: '20px'}}
              className='react-player' // Dodajemy klasę do ReactPlayer
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowBuy;