import React, { useEffect} from 'react';
import './style.css';
import fb from './img/fb.png'
import lIn from './img/in.png'
import insta from './img/insta.png'
import tel from './img/tel.png'
import tt from './img/tt.png'
import tw from './img/tw.png'
import yt from './img/yt.png'

function Social() {

  return (
    <div className="social">
      <ul>
        <li><a href='https://www.youtube.com/@TronGuyProject' target='_onBlank'><img src={yt} alt=''/></a></li>
        <li><a href='https://www.linkedin.com/company/tron-guy-project/' target='_onBlank'><img src={lIn} alt=''/></a></li>
        <li><a href='https://www.facebook.com/tronguyproject' target='_onBlank'><img src={fb} alt=''/></a></li>
        <li><a href='https://www.instagram.com/tronguyproject/' target='_onBlank'><img src={insta} alt=''/></a></li>
        <li><a href='https://t.me/+nQo4t95JfkZlNDNh' target='_onBlank'><img src={tel} alt=''/></a></li>
        <li><a href='https://twitter.com/TRONGUY_TRX' target='_onBlank'><img src={tw} alt=''/></a></li>
        <li><a href='https://www.tiktok.com/@tronguytrx' target='_onBlank'><img src={tt} alt=''/></a></li>
      </ul>
    </div>
  );
}                         

export default Social;
 